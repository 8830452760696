body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GraphikLight';
  font-style: normal;
  font-weight: 300;
  src: local('GraphikLight'),
    url('./../assets/fonts/Graphik/Graphik-Light.otf') format('truetype');
}
@font-face {
  font-family: 'GraphikRegular';
  font-style: normal;
  font-weight: 400;
  src: local('GraphikRegular'),
    url('./../assets/fonts/Graphik/Graphik-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'GraphikMedium';
  font-style: normal;
  font-weight: 500;
  src: local('GraphikMedium'),
    url('./../assets/fonts/Graphik/Graphik-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'GraphikBold';
  font-style: normal;
  font-weight: 700;
  src: local('GraphikBold'),
    url('./../assets/fonts/Graphik/Graphik-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'RalewayLight';
  font-style: normal;
  font-weight: 300;
  src: local('RalewayLight'),
    url('./../assets/fonts/Raleway/Raleway-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayRegular';
  font-style: normal;
  font-weight: 400;
  src: local('RalewayRegular'),
    url('./../assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayItalic';
  font-style: italic;
  font-weight: 400;
  src: local('RalewayItal'),
    url('./../assets/fonts/Raleway/Raleway-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayMedium';
  font-style: normal;
  font-weight: 500;
  src: local('RalewayMedium'),
    url('./../assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewaySemibold';
  font-style: normal;
  font-weight: 600;
  src: local('RalewayBold'),
    url('./../assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'RalewayBold';
  font-style: normal;
  font-weight: 700;
  src: local('RalewayBold'),
    url('./../assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
}

.root-container,
.MuiPopover-root {
  .upload-button {
    color: #000000;
    background-color: #d5a419;
    border-radius: 26px;
    width: 164px;
    padding: 15px 0;
    margin-bottom: 20px;
  }

  .MuiTabs-root {
    .MuiTabsList-root.MuiTabsList-horizontal {
      border-bottom: 1px solid #e0e0e0;

      .MuiTab-root {
        font-size: 16px;
        font-family: 'GraphikMedium', sans-serif;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        padding: 12px 24px;
        border: none;
        margin-bottom: -1px;
        text-transform: uppercase;
        color: rgba(12, 43, 89, 0.38);

        &.Mui-selected {
          border-bottom: 2px solid #d5a419;
          color: #000000;
        }
      }
    }
  }

  .MuiTabPanel-root {
    margin-top: 36px;
  }
}

.MuiFormControl-root {
  [class$='-MuiButtonBase-root-MuiRadio-root'] {
    &.Mui-checked {
      color: #d5a419;
    }
  }
}
