@import './variables.scss';

.auth {
  display: flex;
  height: 100%;
  max-height: 100%;
  .auth__left {
    width: 50%;
    height: 100vh;
    position: relative;
  }
  .auth__right {
    background-color: black;
    color: white;
    width: 50%;
    height: 100vh;
    .auth__form {
      display: block;
      width: 26rem;
      margin: 0 auto;
      margin-top: 300px;
      text-align: center;
      > form div {
        margin: 10px 0;
      }
      .auth__control {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 40px;
      }
      .link {
        text-align: right;
      }
    }
  }
}
