.more-button__menu {
  border-radius: 10px;
  border: 1px solid #eef0f3;
  background: white;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  max-width: 140px;
  width: 140px;

  ul {
    list-style-type: none;
    padding: 16px 24px 16px 16px;
    margin: 0;
  }
}
